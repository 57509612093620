import { Icon } from "@shopify/polaris";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import { Box } from "@smartrr/shared/components/primitives";
import { omit } from "lodash";
import React, { useMemo, useRef } from "react";
import { Dropdown, Loader, Popover, Table, Whisper } from "rsuite";
import styled from "styled-components";

import { tableCellStyle } from "@vendor-app/constants/table";

// https://github.com/rsuite/rsuite/blob/master/docs/pages/components/table/en-US/custom-cell.md

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export interface IActionCellAction<ActionType> {
  type: ActionType;
  label: React.ReactNode;
}

interface ActionCellProps<RowDataType, ActionType> {
  actionCreator(rowData: RowDataType): IActionCellAction<ActionType>[];
  onAction(action: ActionType, rowData?: RowDataType): void;
  checkLoading?(rowData: RowDataType): boolean;
  popoverClassName?: string;
  dataKey?: keyof RowDataType;
  rowData?: RowDataType;
  [key: string]: any;
}

export function ActionCell<RowDataType, ActionType>({
  rowData,
  actionCreator,
  onAction,
  style,
  checkLoading,
  popoverClassName,
  ...props
}: ActionCellProps<RowDataType, ActionType>): JSX.Element {
  const actions = rowData ? actionCreator(rowData) : [];
  const cleanedProps = omit(props, ["dataKey"]);
  const styles = useMemo(() => ({ padding: 0, ...tableCellStyle, ...style }), [style]);

  if (rowData && checkLoading?.(rowData)) {
    return (
      <Table.Cell {...cleanedProps} style={styles}>
        <Box alignItems="center" justifyContent="center" style={{ width: "100%" }}>
          <Loader />
        </Box>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell {...cleanedProps} style={styles}>
      {actions.length ? (
        <CustomWhisper
          className={popoverClassName}
          actions={actions}
          onSelect={action => onAction(action, rowData)}
        >
          <IconWrapper onClick={e => e.stopPropagation()}>
            <Icon source={MobileVerticalDotsMajor} />
          </IconWrapper>
        </CustomWhisper>
      ) : (
        <div> </div>
      )}
    </Table.Cell>
  );
}

function Menu<ActionType>({ actions, onSelect }: { actions: IActionCellAction<ActionType>[]; onSelect(): void }) {
  return (
    <Dropdown.Menu onSelect={onSelect}>
      {actions.map((action, i) => (
        <Dropdown.Item key={i} eventKey={action.type}>
          {action.label}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  );
}

function MenuPopover({ onSelect, style, actions, ...rest }: any) {
  return (
    <Popover {...rest} full style={{ ...style, minWidth: 100 }}>
      <Menu actions={actions} onSelect={onSelect} />
    </Popover>
  );
}

interface CustomWhisperProps<ActionType> {
  onSelect(action: ActionType): void;
  actions: IActionCellAction<ActionType>[];
  children: React.ReactElement;
  className?: string;
}

function CustomWhisper<ActionType>({ onSelect, actions, className, children }: CustomWhisperProps<ActionType>) {
  const trigger = useRef<any>();

  const handleSelectMenu = (eventKey: ActionType, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // prevent table from scrolling to top on menu click
    onSelect(eventKey);
    trigger.current?.hide();
  };

  return (
    <Whisper
      placement="autoVerticalEnd"
      trigger="click"
      ref={trigger}
      speaker={<MenuPopover className={className} actions={actions} onSelect={handleSelectMenu} />}
    >
      {children}
    </Whisper>
  );
}
