import {
  Button,
  Checkbox,
  HorizontalStack,
  Layout,
  VerticalStack,
} from "@shopify/polaris";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { IReChargePurchaseStateTableData } from "@smartrr/shared/interfaces/ReCharge";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { RSuiteSortType, SmartrrSortType } from "@smartrr/shared/utils/paginatedQuery";
import { toViewShortDate } from "@smartrr/shared/utils/renderViewDate";
import { updateMatchInArray } from "@smartrr/shared/utils/updateMatchInArray";
import { ActionCell, IActionCellAction } from "@vendor-app/app/_sharedComponents/TableActionCell/ActionCell";
import { TableHeader } from "@vendor-app/app/_sharedComponents/TableHeader";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  putMigrateReChargePurchaseState,
  putRollbackReChargePurchaseState,
} from "@vendor-app/app/_state/actionCreators/migrations";
import { useActiveOrganizationIdSelector } from "@vendor-app/app/_state/reducers/organizations";
import { SmartRRWebSocket } from "@vendor-app/app/SmartRRWebSocket";
import { DEFAULT_PAGE, DEFAULT_SIZE } from "@vendor-app/constants/table";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { uniqBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Pagination, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

enum ActionCellActions {
  ACTIVATE,
  ROLLBACK,
  // SYNC_ORDERS,
  VIEW,
}

export function ReChargePurchaseStatesTable(): JSX.Element {
  const orgId = useActiveOrganizationIdSelector();
  const { addToast } = useToast();

  const [customerPurchaseStates, setCustomerPurchaseStates] = useState<IReChargePurchaseStateTableData[]>([]);

  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [orderByField, setOrderByField] = useState<keyof IReChargePurchaseStateTableData | string>(
    "nextBillingDate"
  );
  const [orderByValue, setOrderByValue] = useState<RSuiteSortType>("desc");

  const [updatingCps, setUpdatingCps] = useState<IReChargePurchaseStateTableData[]>([]);
  const [activatingAll, setActivatingAll] = useState(false);
  const [rollbackAll, setRollbackAll] = useState(false);
  const [syncingOrders, setSyncingOrders] = useState(false);
  const [syncingCancelledAt, setSyncingCancelledAt] = useState(false);
  const [syncingInitialSubmissionDate, setSyncingInitialSubmissionDate] = useState(false);
  const [hideCancelled, setHideCancelled] = useState(true);
  const [fixingStatus, setFixingStatus] = useState(false);
  const [pendingContractsStatus, setPendingContractsStatus] = useState(false);

  const fetchReChargePurchaseStates = useCallback(async () => {
    setLoading(true);
    const res = await typedFrontendVendorApi.getReq("/integrations/recharge/purchase-states", {
      query: {
        pageNumber: "" + (pageNumber - 1),
        pageSize: "" + pageSize,
        orderBy: {
          [orderByField]: orderByValue.toUpperCase() as SmartrrSortType,
        },
        filterLike: hideCancelled
          ? {
              originalExternalSubscriptionStatus: SubscriptionContractSubscriptionStatus.Active,
            }
          : undefined,
      },
    });

    if (res.type === "success") {
      const { data, totalCount } = res.body;
      setCustomerPurchaseStates(data);
      setTotalCount(totalCount);
    }

    setLoading(false);
  }, [pageNumber, pageSize, orderByValue, orderByField, hideCancelled]);

  const activateSubscription = useCallback(async (cps: IReChargePurchaseStateTableData) => {
    if (!cps?.custRel) {
      return;
    }

    setUpdatingCps(updating => uniqBy(updating.concat(cps), c => c.id));
    const res = await putMigrateReChargePurchaseState(cps.custRel.id, cps.id);
    setUpdatingCps(updating => updating.filter(c => c.id !== cps.id));

    if (res.type === "error") {
      addToast(`Error migrating subscription: ${res.message}`);
      return;
    }

    setCustomerPurchaseStates(states => updateMatchInArray(states, res.body, cps => cps.id));
  }, []);

  const rollbackSubscription = useCallback(async (cps: IReChargePurchaseStateTableData) => {
    if (!cps?.custRel) {
      return;
    }

    setUpdatingCps(updating => uniqBy(updating.concat(cps), c => c.id));
    const res = await putRollbackReChargePurchaseState(cps.custRel.id, cps.id);
    setUpdatingCps(updating => updating.filter(c => c.id !== cps.id));

    if (res.type === "error") {
      addToast(`Error rolling back subscription: ${res.message}`);
      return;
    }

    setCustomerPurchaseStates(states => updateMatchInArray(states, res.body, cps => cps.id));
  }, []);

  useEffect(() => {
    fetchReChargePurchaseStates();
  }, [pageNumber, pageSize, orderByField, orderByValue, hideCancelled]);

  const onSortColumn = useCallback(
    (key: keyof IReChargePurchaseStateTableData | string, sortType: RSuiteSortType) => {
      setOrderByField(key);
      setOrderByValue(sortType);
    },
    [setOrderByField, setOrderByValue]
  );

  const onActivateAll = useCallback(() => {
    if (!orgId) {
      return;
    }

    setActivatingAll(true);
    new Promise(async resolve => {
      const topic = (await SmartRRWebSocket.create(orgId)).createTopicListener(
        "/organization/:orgId/migrate/recharge",
        ({ customerPurchaseState, done, count }) => {
          if (done) {
            setActivatingAll(false);
            addToast(`Done activating subscriptions, ${count} activated`);
            resolve({
              type: "success",
              body: {},
            });
          } else {
            console.log(`${count} subscription(s) activated`);
            if (customerPurchaseState) {
              setCustomerPurchaseStates(cpsArr =>
                // @ts-ignore
                updateMatchInArray(cpsArr, customerPurchaseState, ({ id }) => id)
              );
            }
          }
        }
      );
      await topic.send({ orgId, force: false });
    });
  }, [orgId]);

  const onRollbackAll = useCallback(() => {
    if (!orgId) {
      return;
    }

    setRollbackAll(true);
    new Promise(async resolve => {
      const topic = (await SmartRRWebSocket.create(orgId)).createTopicListener(
        "/organization/:orgId/rollback/recharge",
        ({ customerPurchaseState, done, count }) => {
          if (done) {
            setRollbackAll(false);
            addToast(`Done rolling back subscriptions, ${count} rolled back`);
            resolve({
              type: "success",
              body: {},
            });
          } else {
            console.log(`${count} subscription(s) rolled back`);
            if (customerPurchaseState) {
              setCustomerPurchaseStates(cpsArr =>
                // @ts-ignore
                updateMatchInArray(cpsArr, customerPurchaseState, ({ id }) => id)
              );
            }
          }
        }
      );
      await topic.send({ orgId });
    });
  }, [orgId]);

  const onSyncOrders = useCallback(() => {
    if (!orgId) {
      return;
    }

    setSyncingOrders(true);
    new Promise(async resolve => {
      const topic = (await SmartRRWebSocket.create(orgId)).createTopicListener(
        "/organization/:orgId/sync/recharge/orders",
        ({ customerPurchaseState, done }) => {
          if (done) {
            setSyncingOrders(false);
            addToast(`Done syncing orders`);
            resolve({
              type: "success",
              body: {},
            });
          } else if (customerPurchaseState) {
            console.log(`orders synced for ${customerPurchaseState.id}`);
          }
        }
      );
      await topic.send({ orgId });
    });
  }, [orgId]);

  const onSyncCancelledAt = useCallback(() => {
    if (!orgId) {
      return;
    }

    setSyncingCancelledAt(true);
    new Promise(async resolve => {
      const topic = (await SmartRRWebSocket.create(orgId)).createTopicListener(
        "/organization/:orgId/sync/recharge/cancelled",
        ({ customerPurchaseState, done }) => {
          if (done) {
            setSyncingCancelledAt(false);
            addToast(`Done syncing cancelledAt`);
            resolve({
              type: "success",
              body: {},
            });
          } else if (customerPurchaseState) {
            console.log(`cancelledAt synced for ${customerPurchaseState.id}`);
          }
        }
      );
      await topic.send({ orgId });
    });
  }, [orgId]);

  const onSyncInitialSubmissionDate = useCallback(() => {
    if (!orgId) {
      return;
    }

    setSyncingInitialSubmissionDate(true);
    new Promise(async resolve => {
      const topic = (await SmartRRWebSocket.create(orgId)).createTopicListener(
        "/organization/:orgId/sync/recharge/initialSubmissionDate",
        ({ customerPurchaseState, done }) => {
          if (done) {
            setSyncingInitialSubmissionDate(false);
            addToast(`Done syncing initialSubmissionDate`);
            resolve({
              type: "success",
              body: {},
            });
          } else if (customerPurchaseState) {
            console.log(`initialSubmissionDate synced for ${customerPurchaseState.id}`);
          }
        }
      );
      await topic.send({ orgId });
    });
  }, [orgId]);

  const createDropdownActions = useCallback((rowData: IReChargePurchaseStateTableData) => {
    const { purchaseStateStatus, originalExternalSubscriptionStatus } = rowData;
    const actions: IActionCellAction<ActionCellActions>[] = [];

    actions.push({
      label: "View subscription",
      type: ActionCellActions.VIEW,
    });

    if (
      originalExternalSubscriptionStatus === SubscriptionContractSubscriptionStatus.Cancelled ||
      originalExternalSubscriptionStatus === SubscriptionContractSubscriptionStatus.Expired
    ) {
      return actions;
    }

    if (purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active) {
      actions.push({
        label: "Rollback",
        type: ActionCellActions.ROLLBACK,
      });
    } else {
      actions.push({
        label: "Activate",
        type: ActionCellActions.ACTIVATE,
      });
    }

    return actions;
  }, []);

  const onCellAction = (action: ActionCellActions, rowData: IReChargePurchaseStateTableData) => {
    switch (action) {
      case ActionCellActions.VIEW: {
        navigateWithShopInQuery(
          `${adminRoutePrefix}/subscriptions`,
          {
            customer_id: "" + shopifyGidToNumber(rowData.custRel!.shopifyId!),
            id: "" + shopifyGidToNumber(rowData.shopifyId),
          },
          undefined,
          true
        );
        break;
      }
      case ActionCellActions.ACTIVATE: {
        activateSubscription(rowData);
        break;
      }
      case ActionCellActions.ROLLBACK: {
        rollbackSubscription(rowData);
        break;
      }
    }
  };

  const onFixStatus = async () => {
    setFixingStatus(true);
    await typedFrontendVendorApi.putReq("/integrations/recharge/purchase-state/fix-status");
    setFixingStatus(false);
    console.log("done");
  };

  const onProcessPendingContracts = async () => {
    setPendingContractsStatus(true);
    await typedFrontendVendorApi.postReq("/integrations/recharge/migrate_contracts");
    setPendingContractsStatus(false);
  };

  return (
    <Layout.Section>
      <TableHeader
        title="Subscriptions"
        rightItems={
          <React.Fragment>
            <Checkbox
              label="Show canceled"
              checked={!hideCancelled}
              onChange={() => {
                setHideCancelled(hide => !hide);
                setPageNumber(DEFAULT_PAGE);
              }}
            />
            <VerticalStack gap="1">
              <HorizontalStack gap="1">
                <Button primary onClick={onFixStatus} loading={fixingStatus}>
                  Fix status
                </Button>
                <Button primary onClick={onActivateAll} loading={activatingAll}>
                  Activate all subscriptions
                </Button>
                <Button primary onClick={onRollbackAll} loading={rollbackAll}>
                  Rollback all subscriptions
                </Button>
              </HorizontalStack>
              <HorizontalStack gap="1">
                <Button onClick={onSyncOrders} loading={syncingOrders}>
                  Sync all orders
                </Button>
                <Button onClick={onSyncCancelledAt} loading={syncingCancelledAt}>
                  Sync canceled at dates
                </Button>
                <Button onClick={onSyncInitialSubmissionDate} loading={syncingInitialSubmissionDate}>
                  Sync initial submission dates
                </Button>
                <Button onClick={onProcessPendingContracts} loading={pendingContractsStatus}>
                  Process pending contracts
                </Button>
              </HorizontalStack>
            </VerticalStack>
          </React.Fragment>
        }
      />
      <Table
        data={customerPurchaseStates}
        onSortColumn={(key, sort) => {
          if (sort) {
            onSortColumn(key, sort);
          }
        }}
        sortType={orderByValue}
        sortColumn={orderByField}
        height={400}
        loading={loading}
      >
        <Column width={150}>
          <HeaderCell>Shopify ID</HeaderCell>
          <Cell>{({ shopifyId }) => (shopifyId ? shopifyGidToNumber(shopifyId) : "--")}</Cell>
        </Column>
        <Column width={150}>
          <HeaderCell>ReCharge IDs</HeaderCell>
          <Cell
            style={{
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {({ externalSubscriptionId }) => externalSubscriptionId?.split(",").join(", ")}
          </Cell>
        </Column>
        <Column width={200} sortable>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email">{({ custRel }) => custRel?.email || "--"}</Cell>
        </Column>
        <Column width={100} sortable>
          <HeaderCell>Current status</HeaderCell>
          <Cell dataKey="purchaseStateStatus">{({ purchaseStateStatus }) => purchaseStateStatus}</Cell>
        </Column>
        <Column width={100} sortable>
          <HeaderCell>Current ReCharge status</HeaderCell>
          <Cell dataKey="externalSubscriptionStatus">
            {({ externalSubscriptionStatus }) => externalSubscriptionStatus}
          </Cell>
        </Column>
        <Column width={100} sortable>
          <HeaderCell>Original ReCharge status</HeaderCell>
          <Cell dataKey="originalExternalSubscriptionStatus">
            {({ originalExternalSubscriptionStatus }) => originalExternalSubscriptionStatus}
          </Cell>
        </Column>
        <Column width={150} sortable>
          <HeaderCell>Next billing date</HeaderCell>
          <Cell dataKey="nextBillingDate">
            {({ nextBillingDate }) =>
              nextBillingDate ? toViewShortDate(ISODateString.fromString(nextBillingDate)) : "--"
            }
          </Cell>
        </Column>
        <Column width={50} align="center" fixed="right">
          <HeaderCell>Actions</HeaderCell>
          <ActionCell
            checkLoading={({ id }) => !!updatingCps.find(cps => cps.id === id)}
            actionCreator={createDropdownActions}
            onAction={onCellAction}
          />
        </Column>
      </Table>
      <Pagination
        pages={Math.ceil(totalCount / pageSize)}
        total={totalCount}
        activePage={pageNumber}
        onChangePage={setPageNumber}
      />
    </Layout.Section>
  );
}
