import React from "react";

export const CSVIcon = props => (
  <svg width="44" height="48" viewBox="0 0 44 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_28016_71767)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.82965 0C2.72815 0 1.0249 1.704 1.0249 3.8055V44.1862C1.0249 46.2885 2.72815 47.9917 4.82965 47.9917H39.1602C41.2609 47.9917 42.9657 46.2885 42.9657 44.1862V15.2325L28.7884 0H4.82965V0Z"
        fill="#8C9196"
      />
      <path
        d="M16.2297 32.3975C16.3242 32.5055 16.3782 32.6405 16.3782 32.789C16.3782 33.0725 16.1487 33.329 15.8509 33.329C15.7159 33.329 15.5674 33.275 15.4729 33.1535C14.9464 32.519 14.0547 32.1133 13.2177 32.1133C11.2459 32.1133 9.74743 33.626 9.74743 35.7733C9.74743 37.907 11.2467 39.4325 13.2177 39.4325C14.0682 39.4325 14.9052 39.068 15.4729 38.4058C15.5667 38.2978 15.7159 38.231 15.8509 38.231C16.1622 38.231 16.3782 38.5003 16.3782 38.7845C16.3782 38.9195 16.3242 39.0545 16.2297 39.1625C15.5269 39.8915 14.5684 40.4323 13.2184 40.4323C10.6257 40.4323 8.58643 38.5415 8.58643 35.7733C8.58643 33.005 10.6257 31.1143 13.2184 31.1143C14.5684 31.115 15.5404 31.655 16.2297 32.3975ZM21.3312 40.433C19.9947 40.433 18.9409 39.9875 18.1714 39.3118C18.0499 39.2038 17.9959 39.0553 17.9959 38.9068C17.9959 38.636 18.1984 38.339 18.5224 38.339C18.6304 38.339 18.7519 38.366 18.8464 38.447C19.4674 39.0013 20.3314 39.4325 21.3717 39.4325C22.9789 39.4325 23.4919 38.5685 23.4919 37.8793C23.4919 35.5565 18.1579 36.8398 18.1579 33.6253C18.1579 32.1403 19.4809 31.127 21.2502 31.127C22.4112 31.127 23.4109 31.4773 24.1399 32.0855C24.2614 32.1935 24.3289 32.342 24.3289 32.4905C24.3289 32.7605 24.0994 33.0305 23.8017 33.0305C23.6937 33.0305 23.5722 32.99 23.4777 32.909C22.8162 32.369 21.9927 32.126 21.1684 32.126C20.0884 32.126 19.3182 32.7065 19.3182 33.5578C19.3182 35.5835 24.6522 34.4218 24.6522 37.7848C24.6529 39.0958 23.7619 40.433 21.3312 40.433ZM34.0767 31.9925L30.9574 39.7438C30.8089 40.1083 30.4437 40.3513 30.0664 40.3513H30.0394C29.6479 40.3513 29.2834 40.1083 29.1342 39.7438L26.0277 31.9925C26.0007 31.925 25.9872 31.8575 25.9872 31.7765C25.9872 31.5065 26.2294 31.1818 26.5947 31.1818C26.8242 31.1818 27.0402 31.3168 27.1347 31.5463L30.0514 39.0403L32.9682 31.5463C33.0492 31.3303 33.2652 31.1818 33.5082 31.1818C33.8592 31.1818 34.1157 31.4653 34.1157 31.7765C34.1164 31.844 34.1029 31.925 34.0767 31.9925Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.0007 15.2676V16.0176H33.4007C33.4007 16.0176 28.6667 15.0726 28.8047 10.9873C28.8047 10.9873 28.9607 15.2676 33.307 15.2676H43.0007Z"
        fill="#5C5F62"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8232 0.00439453V10.9251C28.8232 12.1671 29.6512 15.2684 33.4012 15.2684H43.0012L28.8232 0.00439453Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_28016_71767">
        <rect width="43" height="48" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
