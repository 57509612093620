import { createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { AdminMigrationsRoute } from "@vendor-app/app/AdminRoute/AdminMigrationsRoute";

export const Route = createLazyFileRoute("/admin/configure/migrations")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  return <AdminMigrationsRoute />;
}
