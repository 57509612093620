import { Tabs, Page, Spinner } from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";

import styled from "styled-components";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { ManualMigration } from "./Manual";
import { RechargeMigration } from "./Recharge";
import { useLocation } from "@tanstack/react-router";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

enum MigrationsTabs {
  RECHARGE,
  MANUAL,
}

const MigrationsWrapper = styled.div`
  margin: 0 auto;
  max-width: 934px;
  min-width: 620px;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AdminMigrationsRoute(): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(MigrationsTabs.RECHARGE);
  const authIsInitializing = useSmartrrVendorSelector(state => state.auth.isLoading);
  const tabs = useMemo(
    () => [
      { id: "recharge", content: "Recharge" },
      { id: "manual", content: "Manual migrations" },
    ],
    []
  );

  const tabsInner = useMemo(() => {
    if (authIsInitializing) {
      return <Spinner />;
    }
    return (() => {
      switch (selectedTab) {
        case MigrationsTabs.RECHARGE: {
          return <RechargeMigration />;
        }

        case MigrationsTabs.MANUAL: {
          return <ManualMigration />;
        }

        default: {
          return <React.Fragment />;
        }
      }
    })();
  }, [selectedTab, authIsInitializing]);

  function hasBody(response: any): response is { body: any } {
    return response && typeof response.body !== "undefined";
  }

  const downloadFile = async exportFile => {
    try {
      const response = await typedFrontendVendorApi.getReq(`/migration/export?fileName=${exportFile}`);
      if (hasBody(response)) {
        const blob = new Blob([response.body], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = exportFile;
        link.click();
        link.remove();
      } else {
        console.error("Response does not have a body:", response);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const fileName = params.get("exportFile");
    if (fileName) {
      downloadFile(fileName);
    }
  }, [location]);

  return (
    <MigrationsWrapper>
      <Page>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} fitted>
          {tabsInner}
        </Tabs>
      </Page>
    </MigrationsWrapper>
  );
}
