import styled from "styled-components";

export const CardContainer = styled.div`
  margin-top: 1rem;
`;

export const HeaderContainer = styled.div`
  padding: 1.5rem;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainDropZoneWrapper = styled.div<{ hasActiveFile: boolean }>`
  align-items: center;
`;

export const MigrationDropZoneContainer = styled.div`
  .Polaris-DropZone-FileUpload {
    padding: 0;
  }
`;
