import { Badge } from "@shopify/polaris";

import { ManualMigrationStore } from "../../stores/MigrationUpload";

import { GroupedItemsStatus, ImportStatuses } from "./constants";
import React from "react";
import { ExportedFile, MigrationsParsedDataType } from "../types";

const { FAILED, PARTIAL, PROCESSING, SUCCESS, UPLOADED, INVALID } = ImportStatuses;

export function formatDate(updatedDate: string | undefined) {
  if (updatedDate) {
    const date = new Date(updatedDate);
    const formattedMonth = date.toLocaleString("en-US", { month: "short" });

    const hours = date.getHours();
    const dayPeriod = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;

    const minutes = date.getMinutes();
    const minuteString = String(`${minutes < 10 ? "0" : ""}` + minutes);

    return `${formattedMonth} ${date.getDate()} at ${formattedHours}:${minuteString} ${dayPeriod}`;
  }
}

export function getImportStatus(status: ManualMigrationStore["currentUploadedFileStatus"]) {
  if (status) {
    const groupedItems = status.groupedItems;
    const hasPending = groupedItems.some(item => item.status === GroupedItemsStatus.PENDING);
    const hasFailed = groupedItems.some(item => item.status === GroupedItemsStatus.FAILED);
    const hasSuccess = groupedItems.some(item => item.status === GroupedItemsStatus.SUCCESS);
    const hasInvalid = groupedItems.some(item => item.status === GroupedItemsStatus.INVALID);
    const allFailed = groupedItems.every(item => item.status === GroupedItemsStatus.FAILED);
    const allSuccess = groupedItems.every(item => item.status === GroupedItemsStatus.SUCCESS);
    const allPending = groupedItems.every(item => item.status === GroupedItemsStatus.PENDING);

    if (hasInvalid) {
      return ImportStatuses.INVALID;
    }

    if (allPending) {
      return ImportStatuses.UPLOADED;
    }

    if (hasPending) {
      return ImportStatuses.PROCESSING;
    }

    if (allFailed) {
      return ImportStatuses.FAILED;
    }

    if (allSuccess) {
      return ImportStatuses.SUCCESS;
    }

    if (hasFailed && hasSuccess) {
      return ImportStatuses.PARTIAL;
    }

    return ImportStatuses.PROCESSING;
  }
}

export function getParsedBadge(parsedStatuses: ExportedFile[]): MigrationsParsedDataType[] {
  return parsedStatuses.map(parsedStatus => {
    switch (parsedStatus.status) {
      case INVALID: {
        return {
          ...parsedStatus,
          badge: <Badge status="critical">Error</Badge>,
        };
      }
      case UPLOADED: {
        return {
          ...parsedStatus,
          badge: <Badge status="info">Uploaded</Badge>,
        };
      }
      case FAILED: {
        return {
          ...parsedStatus,
          badge: <Badge status="critical">Failed</Badge>,
        };
      }
      case PARTIAL: {
        return {
          ...parsedStatus,
          badge: <Badge status="critical">Partial Failure</Badge>,
        };
      }
      case SUCCESS: {
        return {
          ...parsedStatus,
          badge: <Badge status="success">Complete</Badge>,
        };
      }
      case PROCESSING: {
        return {
          ...parsedStatus,
          badge: <Badge status="warning">Processing</Badge>,
        };
      }
      default: {
        return { ...parsedStatus, badge: <Badge status="critical">Error</Badge> };
      }
    }
  });
}

export function getFileByName(
  statuses: ManualMigrationStore["statuses"],
  file: ManualMigrationStore["file"]
): ManualMigrationStore["currentUploadedFileStatus"] {
  if (statuses && file) {
    return statuses.find(uploadedFile => uploadedFile?.fileName === file.name) ?? null;
  }
  return null;
}

export function getTotalRows(status: ManualMigrationStore["currentUploadedFileStatus"]): number {
  let rows = 0;
  if (status) {
    for (const item of status.groupedItems) {
      rows += item.count;
    }
  }
  return rows;
}

export function formatErrorMessage(error) {
  const convertedError = JSON.parse(error);
  return convertedError.error;
}
