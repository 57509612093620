export const sampleCSV = `subscriptionId,nextPaymentDate,customerEmail,address1,address2,provinceCode,city,countryCode,zip,customerPhone,firstName,lastName,variantId,quantity,currentPrice,stripeCustomerId,shopifyPaymentMethodId,status,sellingPlanId
1234567,2023-10-10,test@test.com,123 1st St,,NY,New York,US,12345,11234567890,test,test,45961316499689,25,50,cus_XxXXXxxxxXXXX,get from current app or Shopify,ACTIVE,gid://shopify/SellingPlan/3205595369`;

export function removeCSVHeadersAfterFirstIteration(originalString: string) {
  const csvHeader =
    "zip;city;address1;address2;lastName;quantity;firstName;variantId;totalValue;countryCode;provinceCode;customerEmail;customerPhone;sellingPlanId;subscriptionId;nextPaymentDate;stripeCustomerId;shopifyPaymentMethodId;feedbackMessage";

  const index = originalString.indexOf(csvHeader);

  if (index === -1 || index + csvHeader.length >= originalString.length) {
    return originalString;
  }

  const firstPart = originalString.substring(0, index + csvHeader.length);
  const secondPart = originalString.substring(index + csvHeader.length);

  const modifiedSecondPart = secondPart.split(csvHeader).join("");

  return firstPart + modifiedSecondPart;
}

export const tableColumns = {
  date: {
    name: "Date",
    paginatedValue: "date",
    filtering: false,
    sorting: false,
    disabled: false,
  },
  badge: {
    name: "Status",
    paginatedValue: "status",
    filtering: false,
    sorting: false,
    disabled: false,
  },
  rows: {
    name: "Rows",
    paginatedValue: "rows",
    filtering: false,
    sorting: false,
    disabled: false,
  },
  fileName: {
    name: "File Name",
    paginatedValue: "fileName",
    filtering: false,
    sorting: false,
    disabled: false,
  },
};

export enum ImportStatuses {
  SUCCESS = "Success",
  PARTIAL = "Partial failure",
  FAILED = "Failed",
  PROCESSING = "Processing",
  UPLOADED = "Uploaded",
  INVALID = "Invalid",
}

export enum GroupedItemsStatus {
  SUCCESS = "success",
  FAILED = "fail",
  PENDING = "pending_process",
  PROCESSING = "processing",
  INVALID = "invalid",
}

export const MAX_ITEMS_ON_PAGE = 5;
