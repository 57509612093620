import { LegacyStack, ProgressBar, Text } from "@shopify/polaris";

import { ManualMigrationStoreAccess } from "../../stores/MigrationUpload";

interface MigrationProgressBarProps {
  animated: boolean;
}

export const MigrationProgressBar = ({ animated }: MigrationProgressBarProps) => {
  const currentUploadedFileStatus = ManualMigrationStoreAccess.useCurrentUploadedFileStatus();
  const rows = ManualMigrationStoreAccess.useRows();
  const progress = ManualMigrationStoreAccess.useProgress();

  return (
    <LegacyStack vertical alignment="center" distribution="center">
      <Text variant="headingLg" as="h1" color="success">
        {progress && progress > 0 ? progress : 0} % complete
      </Text>
      <div style={{ width: 400 }}>
        {<ProgressBar animated={animated} progress={progress && progress > 0 ? progress : 0} />}
      </div>
      {currentUploadedFileStatus ? (
        <Text as="p">
          ({rows.migratedRows}) of ({rows.totalRows}) rows imported.
        </Text>
      ) : null}
      <div style={{ width: 400 }}>
        <Text alignment="center" as="p">
          Please note: migration process may take hours or days depending on the number of subscriptions
          migrating.
        </Text>
      </div>
    </LegacyStack>
  );
};
