import { IOrder } from "shopify-api-node";

import { ISODateString } from "./ISODateString";
import { OrderSource } from "./Order";
import { IReChargeStreamEmission } from "../interfaces/ReCharge";
import { IProductStreamEmission } from "../shopifyGraphQL/purchasables";
import { ISubscriptionContractStreamEmission } from "../shopifyGraphQL/subscriptionContracts";

export enum Status {
  ready,
  syncing,
  succeeded,
  failed,
}

export enum SourceType {
  Order = "order",
  Product = "product",
  Contract = "contract",
  ReChargeSubscription = "rechargeSubscription",
}

export interface IDataStream<ST extends SourceType> {
  id: number;
  orgId: string;
  status: Status;
  sourceJson: ST extends SourceType.Order
    ? IOrder
    : ST extends SourceType.Product
    ? IProductStreamEmission
    : ST extends SourceType.ReChargeSubscription
    ? IReChargeStreamEmission
    : ISubscriptionContractStreamEmission;
  sourceType: ST;
  sourceDate: ISODateString;
  source: OrderSource.Shopify;
  error?: string;
  retry: number;
  createdDate: ISODateString;
}
