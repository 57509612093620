import {
  IDeserializedPaginatedQuery,
  ISerializedPaginatedQuery,
  serializePaginatedQueryValues,
} from "@smartrr/shared/utils/paginatedQuery";
import { ExportedFile, MigrationsParsedDataType, UploadedFileStatus } from "../../libs/types";
import { StateCreator, create } from "zustand";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { formatDate, getImportStatus, getParsedBadge, getTotalRows } from "../../libs/utils";
import { MAX_ITEMS_ON_PAGE, removeCSVHeadersAfterFirstIteration } from "../../libs/utils/constants";
import { immer } from "zustand/middleware/immer";
import { createAndDownloadFile } from "@vendor-app/utils/createAndDownloadCsv";
import { useMemo } from "react";

export interface MigrationTableStore {
  currentPageNumber: number;
  exportedData: string[];
  exportedFiles: File[];
  isExportToCSVLoading: boolean;
  queryParams: ISerializedPaginatedQuery;
  tableLoading: boolean;
  statusList: UploadedFileStatus[];

  actions: {
    updateStatusList(list: UploadedFileStatus[]): void;
    setCurrentPageNumber(pageNumber: number): void;
    exportFile(fileToExport: ExportedFile[]): Promise<void>;
    setQueryParams(queryParams: IDeserializedPaginatedQuery): void;
  };
}

const migrationTableSlice: StateCreator<MigrationTableStore, [["zustand/immer", never]]> = (set, get) => ({
  currentPageNumber: 0,
  exportedData: [],
  isExportToCSVLoading: false,
  exportedFiles: [],
  parsedStatuses: [],
  queryParams: {
    filterAfter: undefined,
    filterBefore: undefined,
    filterEquals: undefined,
    filterIn: undefined,
    filterLike: undefined,
    orderBy: undefined,
    pageNumber: String(0),
    pageSize: String(MAX_ITEMS_ON_PAGE),
  },
  tableImportStatuses: [],
  tableLoading: false,
  totalPageCount: 0,
  statusList: [],

  actions: {
    updateStatusList(list: UploadedFileStatus[]): void {
      set(draft => {
        draft.statusList = list;
      });
    },

    setCurrentPageNumber(pageNumber) {
      set(draft => {
        draft.currentPageNumber = pageNumber;
      });
    },

    async exportFile(filteredResources) {
      for (const resource of filteredResources) {
        const res = await typedFrontendVendorApi.getReq("/migration/export", {
          query: {
            fileName: resource.fileName,
          },
        });

        if (res.type === "success") {
          set(draft => {
            draft.exportedData.push(res.body);
          });
        }
      }

      const exportedData = get().exportedData;
      if (exportedData.length > 0) {
        const combinedData = exportedData.reduce((acc, currentData) => {
          return acc + currentData;
        }, "");

        const modifiedCSVData = removeCSVHeadersAfterFirstIteration(combinedData);

        createAndDownloadFile({
          text: modifiedCSVData,
          fileType: "text/csv;charset=utf-8",
          filename: "file_imports",
        });
        set(draft => {
          draft.exportedData = [];
        });
      }
    },

    setQueryParams(queryParams) {
      set(draft => {
        draft.queryParams = serializePaginatedQueryValues(queryParams);
      });
    },
  },
});

const useMigrationTableStore = create<MigrationTableStore>()(immer(migrationTableSlice));

export const MigrationTableAccess = {
  useActions() {
    return useMigrationTableStore(state => state.actions);
  },

  useIsExportToCSVLoading() {
    return useMigrationTableStore(state => state.isExportToCSVLoading);
  },

  useSelectedFiles(selectedResources: string[]): MigrationsParsedDataType[] {
    const parsedStatuses = MigrationTableAccess.useParsedStatuses();
    return useMemo(() => {
      return parsedStatuses.filter(status => {
        if (selectedResources.includes(status.id)) {
          return status;
        }
      });
    }, [parsedStatuses, selectedResources]);
  },

  useUploadFileStatusList(selectedResources: string[]): UploadedFileStatus[] {
    const statusList = useMigrationTableStore(state => state.statusList);

    return useMemo(() => {
      return statusList.filter(status => {
        if (selectedResources.includes(status.fileName)) {
          return status;
        }
      });
    }, [statusList, selectedResources]);
  },

  useParsedStatuses(): MigrationsParsedDataType[] {
    const statusList = useMigrationTableStore(state => state.statusList);
    const queryParams = useMigrationTableStore(state => state.queryParams);

    const pageSize = useMemo(() => parseInt(queryParams.pageSize ?? `${MAX_ITEMS_ON_PAGE}`), [queryParams]);
    const pageNumber = useMemo(() => parseInt(queryParams.pageNumber ?? `${0}`), [queryParams]);

    return useMemo(() => {
      const startIndex = pageSize * pageNumber;
      const endIndex = startIndex + pageSize;

      return getParsedBadge(
        statusList
          .map(
            (status): ExportedFile => ({
              date: formatDate(status.updatedDate) ?? "",
              status: getImportStatus(status) ?? "",
              id: status.fileName,
              rows: getTotalRows(status),
              fileName: status.fileName,
            })
          )
          .slice(startIndex, endIndex)
      );
    }, [statusList, pageSize, pageNumber]);
  },

  usePageCount() {
    const statusListLength = (useMigrationTableStore(state => state.statusList) ?? []).length;
    return Math.ceil(statusListLength ? statusListLength / MAX_ITEMS_ON_PAGE : 1);
  },

  useTableIsLoading() {
    return useMigrationTableStore(state => state.tableLoading);
  },

  testing: {
    store: useMigrationTableStore,
  },
};
