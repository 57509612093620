import { Checkbox, Modal, Spinner, Text } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { SourceType, Status } from "@smartrr/shared/entities/DataStream";
import { IReChargeCustomerTableData } from "@smartrr/shared/interfaces/ReCharge";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import {
  IDeserializedPaginatedQuery,
  RSuiteSortType,
  SmartrrSortType,
} from "@smartrr/shared/utils/paginatedQuery";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Pagination, Table } from "rsuite";

import { ActionCell, IActionCellAction } from "@vendor-app/app/_sharedComponents/TableActionCell/ActionCell";
import { TableHeader } from "@vendor-app/app/_sharedComponents/TableHeader";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useActiveOrganizationIdSelector } from "@vendor-app/app/_state/reducers/organizations";
import { DEFAULT_PAGE, DEFAULT_SIZE } from "@vendor-app/constants/table";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { AutoCompleteWithTextField } from "../../components/elements/AutocompleteWithTextField";

const { Column, HeaderCell, Cell } = Table;

const textPlaceholder = { placeholder: "Search by email..." };

enum CellActions {
  SHOW_ERROR,
  RETRY,
}

export function ReChargeCustomersTable(): JSX.Element {
  const orgId = useActiveOrganizationIdSelector();
  const { addToast } = useToast();

  const [processedCount, setProcessedCount] = useState(0);
  const [showError, setShowError] = useState<string | null>(null);

  const [processedCountLoading, setProcessedCountLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [orderByField, setOrderByField] = useState<keyof IReChargeCustomerTableData | string>("sourceDate");
  const [orderByValue, setOrderByValue] = useState<RSuiteSortType>("desc");
  const [searchText, setSearchText] = useState("");
  const [showFailedOnly, setShowFailedOnly] = useState(false);

  const [customers, setCustomers] = useState<IReChargeCustomerTableData[]>();

  const fetchReChargeCustomerDataStatus = useCallback(async () => {
    setProcessedCountLoading(true);
    const res = await typedFrontendVendorApi.getReq("/integrations/recharge/customer-processed-count");

    if (res.type === "success") {
      setProcessedCount(res.body.processedCount);
    }

    setProcessedCountLoading(false);
  }, []);

  const fetchReChargeCustomers = async ({
    pageNumber,
    pageSize,
    filterLike,
    orderBy,
  }: IDeserializedPaginatedQuery) => {
    setLoading(true);
    const res = await typedFrontendVendorApi.getReq("/integrations/recharge/customers", {
      query: {
        pageNumber: "" + pageNumber,
        pageSize: "" + pageSize,
        filterLike,
        orderBy,
      },
    });

    if (res.type === "success") {
      const { data, totalCount } = res.body;
      setCustomers(
        data.map(customer => {
          return {
            ...customer,
            custRelShopifyId: customer.custRelShopifyId,
            email: customer.email,
            firstName: customer.firstName,
            lastName: customer.lastName,
            stShopifyId: customer.stShopifyId,
            externalSubscriptionType: customer.externalSubscriptionType,
            sourceType: SourceType[customer.sourceType as keyof typeof SourceType],
          };
        })
      );
      setTotalCount(totalCount);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchReChargeCustomerDataStatus();
  }, []);

  const debouncedQuery = useCallback(
    debounce(
      (orgId: string, queryParams: IDeserializedPaginatedQuery) => fetchReChargeCustomers(queryParams),
      250
    ),
    []
  );

  useEffect(() => {
    if (!orgId) {
      return;
    }
    const filterLike: { [key: string]: any } = {};
    if (searchText) {
      filterLike.email = searchText.trim();
    }
    if (showFailedOnly) {
      filterLike.status = Status.failed;
    }
    // pageNumber index needs to explicitely reduce one, because the specific operation, the minimum will be one
    debouncedQuery(orgId, {
      pageNumber: pageNumber - 1,
      pageSize,
      orderBy: {
        [orderByField]: orderByValue.toUpperCase() as SmartrrSortType,
      },
      filterLike,
    });
  }, [orgId, pageNumber, orderByField, orderByValue, pageSize, searchText, showFailedOnly]);

  useEffect(() => {
    setPageNumber(1);
  }, [searchText]);

  const onSortColumn = useCallback(
    (key: keyof IReChargeCustomerTableData | string, sortType: RSuiteSortType) => {
      setOrderByField(key);
      setOrderByValue(sortType);
    },
    [setOrderByField, setOrderByValue]
  );

  const onRowClick = useCallback((rowData: any) => {
    setShowError(rowData.error);
  }, []);

  const createDropdownActions = useCallback(() => {
    const actions: IActionCellAction<CellActions>[] = [
      {
        label: "View error",
        type: CellActions.SHOW_ERROR,
      },
      {
        label: "Retry",
        type: CellActions.RETRY,
      },
    ];
    return actions;
  }, []);

  const retryCustomer = useCallback(
    async (rowData: IReChargeCustomerTableData) => {
      const res = await typedFrontendVendorApi.putReq(
        "/integrations/recharge/customers/:rechargeCustomerId/retry",
        {
          params: {
            rechargeCustomerId: "" + rowData.sourceJson.customer.id,
          },
        }
      );

      if (res.type === "success") {
        const customerToModifyIndex = (customers || []).findIndex(
          cust => cust.sourceJson.customer.id === rowData.sourceJson.customer.id
        );
        if (!customers || customerToModifyIndex === -1) {
          return;
        }
        setCustomers([
          ...customers.slice(0, customerToModifyIndex),
          {
            ...customers[customerToModifyIndex],
            status: res.body.status as Status,
            error: null,
          },
          ...customers.slice(customerToModifyIndex + 1),
        ]);
      }
    },
    [customers]
  );

  const onCellAction = async (action: CellActions, rowData: IReChargeCustomerTableData) => {
    if (action === CellActions.SHOW_ERROR) {
      setShowError(rowData.error);
    } else if (action === CellActions.RETRY) {
      retryCustomer(rowData);
    }
  };

  return (
    <React.Fragment>
      <TableHeader
        title="Customers"
        rightItems={[
          processedCountLoading || loading ? (
            <Spinner size="small" />
          ) : (
            <Text variant="headingMd" as="h2">
              ({processedCount}/{totalCount}) imported
            </Text>
          ),
          // eslint-disable-next-line react/jsx-key
          <Checkbox
            label="Show failed only"
            onChange={checked => setShowFailedOnly(checked)}
            checked={showFailedOnly}
          />,
          <AutoCompleteWithTextField
            key="autocomplete"
            options={[]}
            textFieldProps={textPlaceholder}
            onChange={setSearchText}
            onSelect={value => setSearchText(Array.isArray(value) ? value[0] : value)}
          />,
        ]}
      />
      <Table
        data={customers}
        loading={loading}
        onSortColumn={(key, sort) => {
          if (sort) {
            onSortColumn(key, sort);
          }
        }}
        sortType={orderByValue}
        sortColumn={orderByField}
        height={400}
        onRowClick={onRowClick}
      >
        <Column width={150}>
          <HeaderCell>ReCharge ID</HeaderCell>
          <Cell>{({ sourceJson }) => sourceJson.customer.id}</Cell>
        </Column>
        <Column width={150} sortable>
          <HeaderCell>Shopify ID</HeaderCell>
          <Cell dataKey="custRelShopifyId">
            {({ custRelShopifyId, sourceJson }) =>
              custRelShopifyId ? shopifyGidToNumber(custRelShopifyId) : sourceJson.customer.shopify_customer_id
            }
          </Cell>
        </Column>
        <Column width={200} sortable>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email">{({ email, sourceJson }) => email || sourceJson.customer.email}</Cell>
        </Column>
        <Column width={125}>
          <HeaderCell>First name</HeaderCell>
          <Cell>{({ firstName, sourceJson }) => firstName || sourceJson.customer.first_name}</Cell>
        </Column>
        <Column width={125}>
          <HeaderCell>Last name</HeaderCell>
          <Cell>{({ lastName, sourceJson }) => lastName || sourceJson.customer.last_name}</Cell>
        </Column>
        <Column width={100} sortable>
          <HeaderCell>Import status</HeaderCell>
          <Cell>
            {({ status }) => <span style={{ color: getStatusColor(status) }}>{getStatusText(status)}</span>}
          </Cell>
        </Column>
        <Column width={50} align="center" fixed="right">
          <HeaderCell>&nbsp;</HeaderCell>
          <ActionCell actionCreator={createDropdownActions} onAction={onCellAction} />
        </Column>
      </Table>
      <Pagination
        pages={Math.ceil(totalCount / pageSize)}
        total={totalCount}
        activePage={pageNumber}
        onChangePage={setPageNumber}
      />

      <Modal
        title="Error details"
        open={!!showError}
        onClose={() => setShowError(null)}
        primaryAction={{
          content: "Close",
          onAction() {
            setShowError(null);
          },
        }}
        secondaryActions={[
          {
            content: "Copy",
            onAction() {
              if (showError) {
                copyToClipboard(decodeURIComponent(showError), "Data copied to clipboard", addToast);
              }
            },
          },
        ]}
      >
        <Modal.Section>
          <Box p={1}>
            <Box>
              <pre style={{ fontFamily: "monospace", margin: 0, whiteSpace: "pre-wrap" }}>
                {!!showError && decodeURIComponent(showError)}
              </pre>
            </Box>
          </Box>
        </Modal.Section>
      </Modal>
    </React.Fragment>
  );
}

function getStatusColor(status: Status) {
  switch (status) {
    case Status.ready: {
      return "black";
    }
    case Status.syncing: {
      return "#c38a1f";
    }
    case Status.succeeded: {
      return "green";
    }
    case Status.failed: {
      return "red";
    }
  }
}

function getStatusText(status: Status) {
  switch (status) {
    case Status.ready: {
      return "Pending";
    }
    case Status.syncing: {
      return "Processing";
    }
    case Status.succeeded: {
      return "Succeeded";
    }
    case Status.failed: {
      return "Failed";
    }
  }
}
