import { Button, Card, LegacyStack, Link, Page, Text } from "@shopify/polaris";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import React, { useCallback, useEffect } from "react";

import { MigrationDropZone } from "./components/DropZone";
import { MigrationImportsTable } from "./components/MigrationTable";
import { sampleCSV } from "./libs/utils/constants";
import { ManualMigrationStoreAccess, MigrationStatusEnum } from "./stores/MigrationUpload";

import { MainDropZoneWrapper } from "./styles";
import { useMigrationStatusHandler } from "./hooks/StatusHandler";
import { MigrationProgressModal } from "./components/ProgressModal";

export const ManualMigration = () => {
  const user = useSmartrrVendorSelector(state => state.auth.user);
  const migrationActions = ManualMigrationStoreAccess.useActions();
  const status = ManualMigrationStoreAccess.useMigrationStatus();
  const file = ManualMigrationStoreAccess.useFile();

  useMigrationStatusHandler();

  useEffect(() => {
    migrationActions.setUser(user);
  }, [!user]);

  const downloadCSV = useCallback(() => {
    // Workaround to create csv file and download
    const blob = new Blob([sampleCSV], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "migration_data_template.csv");
    link.click();

    URL.revokeObjectURL(url);
  }, []);

  return (
    <Page title="Manual migrations">
      <LegacyStack spacing="loose" vertical>
        <Card padding="600">
          <LegacyStack spacing="loose" vertical>
            <Text as="p" variant="bodyMd">
              Download a&nbsp;
              <Link onClick={downloadCSV}>sample template</Link>&nbsp; to see an example of the format required.
            </Text>
            <MainDropZoneWrapper hasActiveFile={!!file}>
              <MigrationDropZone />
            </MainDropZoneWrapper>
          </LegacyStack>
        </Card>
        <LegacyStack distribution="trailing">
          <Button
            primary={true}
            disabled={status !== MigrationStatusEnum.FILE_DROPPED}
            onClick={async () => {
              if (file) {
                await migrationActions.fileUpload(file);
              }
            }}
          >
            Import Data
          </Button>
        </LegacyStack>
      </LegacyStack>
      <MigrationImportsTable />
      <MigrationProgressModal />
    </Page>
  );
};
