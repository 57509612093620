import { LegacyCard, Spinner, Text, useIndexResourceState } from "@shopify/polaris";
import { usePolarisTypedTable } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useTableHandlers } from "@vendor-app/app/_sharedComponents/TypedTable/useTableHandlers";
import { PaginationContainer } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";
import React, { useEffect, useMemo } from "react";

import { MAX_ITEMS_ON_PAGE, tableColumns } from "../../libs/utils/constants";
import { useFilterUpdate } from "@vendor-app/app/_sharedComponents/TableSearch/libs";

import { CardContainer, HeaderContainer, SpinnerContainer } from "../../styles";
import { MigrationTableAccess } from "../../stores/MigrationTable";
import { useMigrationProgressModalStore } from "../ProgressModal/store";

export const MigrationImportsTable = () => {
  const { Pagination, Table } = usePolarisTypedTable();

  const actions = MigrationTableAccess.useActions();
  const parsedStatuses = MigrationTableAccess.useParsedStatuses();
  const pageCount = MigrationTableAccess.usePageCount();
  const isExportToCSVLoading = MigrationTableAccess.useIsExportToCSVLoading();
  const tableIsLoading = MigrationTableAccess.useTableIsLoading();
  const [tableProps, tableHandlers] = useTableHandlers("date", "ASC", 1, MAX_ITEMS_ON_PAGE);
  const openMigrationModal = useMigrationProgressModalStore(state => state.openModal);

  const [debouncedUpdate] = useFilterUpdate(({ queryParams }) => {
    if (queryParams) {
      actions.setQueryParams(queryParams);
    }
  }, tableHandlers.setPageNumber);

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    parsedStatuses.map(parsedStatus => ({ id: parsedStatus.id }))
  );

  const selectedFiles = MigrationTableAccess.useSelectedFiles(selectedResources);
  const selectedUploadStatus = MigrationTableAccess.useUploadFileStatusList(selectedResources);

  const promotedBulkActions = useMemo(() => {
    return [
      {
        content: "Download import report",
        onAction: () => actions.exportFile(selectedFiles),
        isLoading: isExportToCSVLoading,
        disabled: isExportToCSVLoading,
      },
      {
        content: "View progress",
        onAction: () =>
          selectedUploadStatus.length === 1 && openMigrationModal({ status: selectedUploadStatus[0] }),
        disabled: isExportToCSVLoading || selectedUploadStatus.length !== 1,
      },
    ];
  }, [isExportToCSVLoading, selectedFiles, selectedUploadStatus]);

  useEffect(() => {
    if (parsedStatuses && parsedStatuses.length > 0) {
      const { orderByField, orderByValue, pageNumber, pageSize } = tableProps;
      debouncedUpdate(pageNumber, pageSize, orderByField, orderByValue, false, {
        ...tableProps.filter,
      });
    }
  }, [tableProps.pageNumber, tableProps.pageSize, debouncedUpdate]);

  return (
    <React.Fragment>
      <CardContainer>
        <LegacyCard>
          <HeaderContainer>
            <Text as="h3" variant="headingLg">
              Past imports
            </Text>
          </HeaderContainer>
          {tableIsLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : parsedStatuses ? (
            <Table
              selectable
              sortable={[false]}
              columns={tableColumns}
              data={parsedStatuses}
              emptyStateText="No past imports"
              resourceName={{
                singular: "import",
                plural: "imports",
              }}
              itemCount={parsedStatuses.length < MAX_ITEMS_ON_PAGE ? parsedStatuses.length : MAX_ITEMS_ON_PAGE}
              promotedBulkActions={promotedBulkActions.filter((x): x is any => x !== null)}
              selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
              onSelectionChange={handleSelectionChange}
              isRowSelected={id => isRowSelected(id, selectedResources)}
            />
          ) : null}
          <LegacyCard.Section subdued>
            <PaginationContainer>
              <Pagination
                hasNext={pageCount > tableProps.pageNumber}
                hasPrevious={tableProps.pageNumber !== 1}
                label={`Showing ${tableProps.pageNumber} of ${pageCount}`}
                onNext={() => tableHandlers.setPageNumber(p => p + 1)}
                onPrevious={() => tableHandlers.setPageNumber(p => p - 1)}
              />
            </PaginationContainer>
          </LegacyCard.Section>
        </LegacyCard>
      </CardContainer>
    </React.Fragment>
  );
};
