import { Banner, Button, Layout, LegacyCard, LegacyStack, Page, Spinner } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import React, { useCallback } from "react";

import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import {
  useShopEligibleForSubscriptionMigration,
  useShopSubscriptionGatewayEnabled,
} from "@vendor-app/utils/useShopSubscriptionGatewayEnabled";

import { ReChargeCustomersTable } from "./ReChargeCustomersTable";
import { ReChargeDataStreamTable } from "./ReChargeDataStreamTable";
import { ReChargePurchaseStatesTable } from "./ReChargePurchaseStatesTable";
import StripeConnect from "../../AdminSuperUserRoute/SyncActions/stripe";

export function RechargeMigration(): JSX.Element {
  const isSuperUser = useSmartrrVendorSelector(state => state.auth.user?.isSuperUser);
  const activeOrg = useActiveOrganizationSelector();

  const { loading: legacySubscriptionGatewayEnabledLoading, enabled: legacySubscriptionGatewayEnabled } =
    useShopSubscriptionGatewayEnabled();
  const {
    loading: shopEligibleForSubscriptionMigrationLoading,
    enabled: shopEligibleForSubscriptionMigrationData,
  } = useShopEligibleForSubscriptionMigration();

  const openConnectToStripe = useCallback(() => {
    window.open(
      `${window.location.protocol}//${activeOrg?.myShopifyDomain || ""}/admin/payments/legacy_subscription`
    );
  }, [activeOrg]);

  return (
    <React.Fragment>
      <Page fullWidth title="Migrate from ReCharge">
        {!shopEligibleForSubscriptionMigrationLoading && !shopEligibleForSubscriptionMigrationData && (
          <Box mb={2}>
            <Banner status="warning" title="Shop ineligible for subscription migration">
              Smartrr has detected that the shop is ineligible for subscription migration. Please refer to
              Shopify&apos;s&nbsp;
              <a href="https://shopify.dev/tutorials/migrate-existing-subscription-contracts-to-shopify#connect-stripe-as-a-secondary-payment-gateway">
                documentation
              </a>
              &nbsp; for more information.
            </Banner>
          </Box>
        )}
        <Layout>
          <Layout.Section>
            <LegacyCard title="Connect Stripe">
              {legacySubscriptionGatewayEnabledLoading ? (
                <LegacyStack alignment="center" distribution="center">
                  <Spinner />
                </LegacyStack>
              ) : (
                <React.Fragment>
                  <LegacyCard.Section>
                    <LegacyStack vertical spacing="tight">
                      <div>
                        Use the below button to connect Smartrr to Stripe to enable importing of customer payment
                        data.
                      </div>
                      <StripeConnect />
                    </LegacyStack>
                  </LegacyCard.Section>
                  <LegacyCard.Section>
                    <LegacyStack vertical spacing="tight">
                      <div>Use the below button to enable Stripe as a secondary payment gateway on Shopify.</div>
                      <Button
                        disabled={legacySubscriptionGatewayEnabled}
                        loading={legacySubscriptionGatewayEnabledLoading}
                        onClick={openConnectToStripe}
                      >
                        {legacySubscriptionGatewayEnabled
                          ? "Stripe connected"
                          : "Connect Stripe as a secondary gateway"}
                      </Button>
                    </LegacyStack>
                  </LegacyCard.Section>
                </React.Fragment>
              )}
            </LegacyCard>
          </Layout.Section>
          {!!isSuperUser && (
            <Layout.Section>
              <ReChargeCustomersTable />
            </Layout.Section>
          )}
          {!!isSuperUser && <ReChargePurchaseStatesTable />}
          {!!isSuperUser && (
            <Layout.Section>
              <ReChargeDataStreamTable />
            </Layout.Section>
          )}
        </Layout>
      </Page>
    </React.Fragment>
  );
}
