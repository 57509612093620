import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export function putMigrateReChargePurchaseState(customerRelationshipId: string, customerPurchaseStateId: string) {
  return typedFrontendVendorApi.putReq("/integrations/recharge/purchase-state/:customerPurchaseStateId/migrate", {
    params: {
      customerRelationshipId,
      customerPurchaseStateId,
    },
  });
}

export function putRollbackReChargePurchaseState(
  customerRelationshipId: string,
  customerPurchaseStateId: string
) {
  return typedFrontendVendorApi.putReq(
    "/integrations/recharge/purchase-state/:customerPurchaseStateId/rollback",
    {
      params: {
        customerRelationshipId,
        customerPurchaseStateId,
      },
    }
  );
}
