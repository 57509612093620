import { useEffect, useState } from "react";
import { ManualMigrationStoreAccess, MigrationStatusEnum } from "../../stores/MigrationUpload";
import { noawait } from "@smartrr/shared/utils/noawait";
import { useFetchAndUpdateStatus } from "../UpdateStatus";

const NUMBER_OF_STATUS_POLLS = 10;
const STATUS_POLL_TIMING = 5000;
const UPLOAD_COMPLETED_TIMING = 1000;

export const useMigrationStatusHandler = () => {
  const isUploadingOrCommitting = ManualMigrationStoreAccess.useIsUploadingOrCommitting();
  const status = ManualMigrationStoreAccess.useMigrationStatus();
  const fetchAndUpdateStatus = useFetchAndUpdateStatus();

  const [statusPolls, setStatusPolls] = useState(NUMBER_OF_STATUS_POLLS);

  useEffect(() => {
    noawait(async () => await fetchAndUpdateStatus());
  }, []);

  useEffect(() => {
    if (statusPolls > 0 && isUploadingOrCommitting) {
      const timeoutId = setTimeout(async () => {
        await fetchAndUpdateStatus();
        setStatusPolls(statusPolls - 1);
      }, STATUS_POLL_TIMING);
      return () => clearTimeout(timeoutId);
    }
  }, [statusPolls, isUploadingOrCommitting]);

  useEffect(() => {
    if (
      status === MigrationStatusEnum.FILE_ERROR ||
      status === MigrationStatusEnum.FILE_COMMITTED ||
      status === MigrationStatusEnum.FILE_UPLOADED
    ) {
      const timeoutId = setTimeout(() => {
        noawait(async () => await fetchAndUpdateStatus());
      }, UPLOAD_COMPLETED_TIMING);
      setStatusPolls(NUMBER_OF_STATUS_POLLS);
      return () => clearTimeout(timeoutId);
    }
  }, [status]);
};
