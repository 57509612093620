import { Badge, Button, IndexTable, LegacyCard, useIndexResourceState } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";

import { TableHeader } from "@vendor-app/app/_sharedComponents/TableHeader";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

function getStatusMessage(status: number) {
  switch (status) {
    case 0: {
      return "Pending";
    }
    case 1: {
      return "Syncing";
    }
    case 2: {
      return "Success";
    }
    case 3: {
      return "Error";
    }
  }
}

function getBadge(status: number) {
  switch (status) {
    case 0: {
      return "info";
    }
    case 1: {
      return "warning";
    }
    case 2: {
      return "success";
    }
    case 3: {
      return "critical";
    }
  }
}

export function ReChargeDataStreamTable(): JSX.Element {
  const [, setLoading] = useState(true);
  const [pendingContractsStatus, setPendingContractsStatus] = useState(false);
  const [dataStreamState, setDataStreamState] = useState<any[]>([]);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(dataStreamState);

  const fetchPendingContracts = useCallback(async () => {
    setLoading(true);
    const res = await typedFrontendVendorApi.getReq("/integrations/recharge/pending_contracts");
    if (res.type === "success") {
      setDataStreamState(res.body);
    }
    while (selectedResources.length > 0) {
      selectedResources.pop();
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchPendingContracts();
  }, []);

  const onProcessPendingContracts = async () => {
    setPendingContractsStatus(true);
    await typedFrontendVendorApi.postReq("/integrations/recharge/migrate_contracts", {
      reqBody: {
        selectedResources,
      },
    });
    setPendingContractsStatus(false);
    fetchPendingContracts();
  };

  const rowMarkup = dataStreamState.map(
    ({ dataStreamId, subscriptionId, dataStreamStatus, dataStreamError }, index) => (
      <IndexTable.Row
        id={dataStreamId}
        key={dataStreamId}
        selected={selectedResources.includes(dataStreamId)}
        position={index}
      >
        <IndexTable.Cell>{dataStreamId}</IndexTable.Cell>
        <IndexTable.Cell>{subscriptionId}</IndexTable.Cell>
        <IndexTable.Cell>
          <Badge status={getBadge(dataStreamStatus)}>{getStatusMessage(dataStreamStatus)}</Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>{dataStreamError}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );
  const resourceName = {
    singular: "Pending Contract",
    plural: "Pending Contracts",
  };

  return (
    <LegacyCard>
      <TableHeader
        title="Pending contracts"
        rightItems={
          <React.Fragment>
            <Button onClick={onProcessPendingContracts} loading={pendingContractsStatus}>
              Start process
            </Button>
          </React.Fragment>
        }
      />
      <IndexTable
        resourceName={resourceName}
        itemCount={dataStreamState.length}
        selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
        onSelectionChange={handleSelectionChange}
        headings={[
          { title: "DataStream Id" },
          { title: "Subscription Id" },
          { title: "Status" },
          { title: "Message" },
        ]}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );
}
