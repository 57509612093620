import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { useCallback } from "react";
import { ManualMigrationStoreAccess } from "../../stores/MigrationUpload";
import { MigrationTableAccess } from "../../stores/MigrationTable";

export const useFetchAndUpdateStatus = () => {
  const migrationActions = ManualMigrationStoreAccess.useActions();
  const migrationTableActions = MigrationTableAccess.useActions();

  const fetchAndUpdateStatus = useCallback(async () => {
    const res = await typedFrontendVendorApi.getReq("/migration/status");
    if (res.type === "success") {
      migrationActions.updateStatusList(res.body);
      migrationTableActions.updateStatusList(res.body);
    }
  }, []);

  return fetchAndUpdateStatus;
};
