import { Box, Button, DropZone, HorizontalStack, Spinner, Text, VerticalStack } from "@shopify/polaris";
import { ArrowUpIcon } from "@vendor-app/app/_sharedComponents/Icons/ArrowUpIcon";
import { CSVIcon } from "@vendor-app/app/_sharedComponents/Icons/CSVIcon";
import React, { useCallback, useMemo } from "react";
import { MigrationDropZoneContainer } from "../../styles";

import { MigrationProgressBar } from "../ProgressBar";
import { ManualMigrationStoreAccess, MigrationStatusEnum } from "../../stores/MigrationUpload";
import { DateTime } from "luxon";
import { noawait } from "@smartrr/shared/utils/noawait";

const MigrationZoneNoFile = () => {
  return (
    <Box padding="16">
      <VerticalStack align="space-around" inlineAlign="center" gap="4">
        <ArrowUpIcon />
        <Button>Add file</Button>
        <Text as="p" variant="bodyMd" color="subdued">
          or drop files to upload
        </Text>
      </VerticalStack>
    </Box>
  );
};

interface MigrationZoneFileProps {
  file: File;
}

const MigrationZoneFileDropped = ({ file }: MigrationZoneFileProps) => {
  return (
    <VerticalStack align="center" inlineAlign="center">
      <Box padding="16">
        <HorizontalStack blockAlign="center" gap="4">
          <CSVIcon />
          <Text as="p" variant="bodyMd" color="subdued">
            {file.name}
          </Text>
        </HorizontalStack>
      </Box>
    </VerticalStack>
  );
};

const MigrationZoneFileUploading = ({ file }: MigrationZoneFileProps) => {
  const isUploadingOrCommitting = ManualMigrationStoreAccess.useIsUploadingOrCommitting();

  return (
    <VerticalStack align="center" inlineAlign="center">
      <Box padding={isUploadingOrCommitting ? "8" : "16"}>
        <HorizontalStack blockAlign="center" gap="4">
          <CSVIcon />
          <Text as="p" variant="bodyMd" color="subdued">
            {file.name}
          </Text>
        </HorizontalStack>
      </Box>
      <Box paddingBlockStart="4" paddingBlockEnd="4">
        <VerticalStack align="center" inlineAlign="center">
          <Spinner size="large" />
          <Text as="span" variant="bodyMd" color="subdued">
            Uploading {file.name}
          </Text>
        </VerticalStack>
      </Box>
    </VerticalStack>
  );
};

const MigrationFileWithDate = ({ file }: MigrationZoneFileProps) => {
  const fileStatus = ManualMigrationStoreAccess.useCurrentUploadedFileStatus();

  const uploadedAt: string = useMemo(() => {
    if (!fileStatus) {
      return "";
    }
    const date = DateTime.fromISO(fileStatus.updatedDate);

    return `${date.toLocaleString({
      day: "2-digit",
      month: "short",
    })} at ${date.toLocaleString({
      hour: "numeric",
      hour12: true,
    })}`;
  }, [fileStatus]);

  return (
    <HorizontalStack align="space-between">
      <HorizontalStack blockAlign="center" gap="4">
        <CSVIcon />
        <Text as="p" variant="bodyMd" color="subdued">
          {file.name}
        </Text>
      </HorizontalStack>
      <HorizontalStack blockAlign="center">
        <Text as="span" variant="bodyMd" color="subdued">
          {uploadedAt}
        </Text>
      </HorizontalStack>
    </HorizontalStack>
  );
};

const MigrationZoneFileUploaded = ({ file }: MigrationZoneFileProps) => {
  const actions = ManualMigrationStoreAccess.useActions();

  return (
    <Box padding="8">
      <VerticalStack gap="6">
        <MigrationFileWithDate file={file} />

        <Box paddingInlineStart="16" paddingInlineEnd="16">
          <VerticalStack align="center" inlineAlign="center" gap="3">
            <Text as="p" variant="headingLg" color="success" alignment="center">
              Import processed successfully
            </Text>
            <Text as="p" variant="bodyMd" color="subdued" alignment="center">
              Click &quot;Commit&quot; to save the imported data to the Shopify store or &quot;Replace&quot; to
              upload a different file.
            </Text>
          </VerticalStack>
        </Box>

        <HorizontalStack align="end" gap="3">
          <Button>Replace</Button>
          <div
            onClick={ev => {
              /**
               * Avoid opening the drop zone file dialog.
               */
              ev.stopPropagation();
              noawait(async () => await actions.initializeMigration(file));
            }}
          >
            <Button primary>Commit</Button>
          </div>
        </HorizontalStack>
      </VerticalStack>
    </Box>
  );
};

const MigrationZoneFileCommitting = ({ file }: MigrationZoneFileProps) => {
  return (
    <Box padding="8">
      <VerticalStack gap="6">
        <MigrationFileWithDate file={file} />
        <HorizontalStack align="center" blockAlign="center">
          <MigrationProgressBar animated={true} />
        </HorizontalStack>
      </VerticalStack>
    </Box>
  );
};

const MigrationZoneFileCommitted = ({ file }: MigrationZoneFileProps) => {
  return (
    <Box padding="8">
      <VerticalStack gap="6">
        <MigrationFileWithDate file={file} />

        <HorizontalStack align="center" blockAlign="center">
          <MigrationProgressBar animated={false} />
        </HorizontalStack>
      </VerticalStack>
    </Box>
  );
};

const MigrationZoneFileError = () => {
  const { errorMessage, showError, critical } = ManualMigrationStoreAccess.useError();
  const currentStatus = ManualMigrationStoreAccess.useCurrentUploadedFileStatus();

  if (!showError) {
    return <React.Fragment />;
  }

  return (
    <Box padding="16">
      <VerticalStack align="space-around" inlineAlign="center" gap="4">
        <ArrowUpIcon />
        <DropZone.FileUpload />
        <Text as="p" variant="headingLg" color="critical" alignment="center">
          Import process failed
        </Text>
        <Text as="p" variant="bodyMd" alignment="center" color="subdued" breakWord={true}>
          Data has one or more errors and can not be imported.{" "}
          {critical ? null : "Please download the file and fix errors before retrying"}
        </Text>
        <Text as="p" variant="bodyMd" alignment="start" color="subdued" breakWord={true}>
          {errorMessage
            .split("\n")
            .filter(msg => msg !== "")
            .map(message => (
              <span>
                {message}
                <br />
              </span>
            ))}
        </Text>
      </VerticalStack>
    </Box>
  );
};

const MigrationDropZoneStatus = () => {
  const status = ManualMigrationStoreAccess.useMigrationStatus();
  const file = ManualMigrationStoreAccess.useFile();

  if (!file) {
    return <MigrationZoneNoFile />;
  }

  switch (status) {
    case MigrationStatusEnum.FILE_DROPPED: {
      return <MigrationZoneFileDropped file={file} />;
    }
    case MigrationStatusEnum.FILE_UPLOADING: {
      return <MigrationZoneFileUploading file={file} />;
    }
    case MigrationStatusEnum.FILE_UPLOADED: {
      return <MigrationZoneFileUploaded file={file} />;
    }
    case MigrationStatusEnum.FILE_COMMITTING: {
      return <MigrationZoneFileCommitting file={file} />;
    }
    case MigrationStatusEnum.FILE_COMMITTED: {
      return <MigrationZoneFileCommitted file={file} />;
    }
    case MigrationStatusEnum.FILE_ERROR: {
      return <MigrationZoneFileError />;
    }
    default: {
      return <React.Fragment />;
    }
  }
};

export const MigrationDropZone = () => {
  const actions = ManualMigrationStoreAccess.useActions();
  const isUploadingOrCommitting = ManualMigrationStoreAccess.useIsUploadingOrCommitting();
  const status = ManualMigrationStoreAccess.useMigrationStatus();

  const onDropZoneDrop = useCallback(
    async (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
      actions.setFile(acceptedFiles[0]);
    },
    []
  );

  return (
    <MigrationDropZoneContainer data-testid="manual-migration-upload-zone">
      <DropZone
        outline={!isUploadingOrCommitting}
        accept="text/csv"
        allowMultiple={false}
        onDrop={onDropZoneDrop}
        disabled={isUploadingOrCommitting || status === MigrationStatusEnum.FILE_COMMITTED}
        variableHeight={true}
      >
        <MigrationDropZoneStatus />
      </DropZone>
    </MigrationDropZoneContainer>
  );
};
