import { Button, Modal, Text, VerticalStack } from "@shopify/polaris";
import { useMigrationProgressModalStore } from "./store";
import React, { useCallback, useMemo, useState } from "react";
import { GroupedItemsStatus } from "../../libs/utils/constants";
import { useFetchAndUpdateStatus } from "../../hooks/UpdateStatus";
import { delay } from "@smartrr/shared/utils/delay";

const REFRESH_DELAY_TIME = 10000;

export const MigrationProgressModal = () => {
  const { isModalOpen, closeModal, modalPayload } = useMigrationProgressModalStore();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const fetchAndUpdateStatus = useFetchAndUpdateStatus();

  const [progressMap, progressCount] = useMemo(() => {
    let progressCount = 0;
    const map: Record<GroupedItemsStatus, number> = {
      [GroupedItemsStatus.FAILED]: 0,
      [GroupedItemsStatus.INVALID]: 0,
      [GroupedItemsStatus.PENDING]: 0,
      [GroupedItemsStatus.PROCESSING]: 0,
      [GroupedItemsStatus.SUCCESS]: 0,
    };

    if (!modalPayload) {
      return [map, progressCount];
    }

    for (const item of modalPayload.status.groupedItems) {
      map[item.status] += item.count;
      progressCount += item.count;
    }

    return [map, progressCount];
  }, [modalPayload]);

  const onRefreshStatus = useCallback(async () => {
    if (isRefreshing) {
      return;
    }

    setIsRefreshing(true);
    await fetchAndUpdateStatus();
    await delay(REFRESH_DELAY_TIME);
    setIsRefreshing(false);
  }, [isRefreshing]);

  if (!isModalOpen || !modalPayload) {
    return <React.Fragment />;
  }

  return (
    <Modal open={isModalOpen} onClose={closeModal} title={`Status for ${modalPayload.status.fileName}`}>
      <Modal.Section>
        <VerticalStack gap="4">
          <Text as="p" variant="bodyMd">
            Pending: {progressMap[GroupedItemsStatus.PENDING] ?? 0}/{progressCount}
          </Text>
          <Text as="p" variant="bodyMd">
            Processing: {progressMap[GroupedItemsStatus.PROCESSING] ?? 0}/{progressCount}
          </Text>
          <Text as="p" variant="bodyMd">
            Successful: {progressMap[GroupedItemsStatus.SUCCESS] ?? 0}/{progressCount}
          </Text>
          <Text as="p" variant="bodyMd">
            Failed: {progressMap[GroupedItemsStatus.FAILED] ?? 0}/{progressCount}
          </Text>
          <Text as="p" variant="bodyMd">
            Invalid: {progressMap[GroupedItemsStatus.INVALID] ?? 0}/{progressCount}
          </Text>
          <Text as="p" variant="bodyMd">
            Total: {progressCount}
          </Text>
          <Button onClick={onRefreshStatus} disabled={isRefreshing} size="slim">
            {isRefreshing ? "Please wait" : "Refresh"}
          </Button>
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
};
